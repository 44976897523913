<template>
  <div class="bankAccountDetails page-info-content">
    <el-tabs v-model="activeName">
      <el-tab-pane name="one" label="开户信息">
        <DetailsPage :detail-obj="meetingInformationDetails" :details-item-arr="detailsItemArr" title="" />
      </el-tab-pane>
      <el-tab-pane name="two" label="提现账户">
        <Table :item-data="itemData" :list-data="listData" />
        <el-button size="small" class="widen-button" type="primary" @click="addCashAccount">
          新增绑定
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="dialog"
      dialog-width="35%"
      :edit-form-data="addFormData"
      :form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Table from '@/components/Table'
import Dialog from '@/components/Dialog'
import DetailsPage from '@/components/DetailsPage'
import { cmpBankinfoDetail, cashAccountDetail, cashAccountAdd } from '@/api/accountManagementSystem'
export default {
  components: { Table, Dialog, DetailsPage },
  data() {
    return {
      activeName: 'one',
      itemData: [
        { label: '开户行名称', prop: 'openBankName' },
        {
          label: '开户行账号', prop: 'openAccNo', rules: [
            { required: true, message: '请输入开户行账号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
          ]
        },
        { label: '开户行网点', prop: 'openBankNet' }
      ],
      meetingInformationDetails: {},
      addAndEditFormItem: [
        { type: 'input', label: '开户行名称', value: 'openBankName' },
        {
          type: 'input', label: '开户行账号', value: 'openAccNo', rules: [
            { required: true, message: '请输入开户行账号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
          ]
        },
        { type: 'input', label: '开户行网点', value: 'openBankNet' }
      ],
      listData: [],
      addFormData: {},
      detailsItemArr: [
        { label: '会员编码', value: 'cmpId' },
        { label: '会员名称', value: 'cmpName' },
        { label: '结算银行', value: 'settleBankName' },
        { label: '银行席位号', value: 'bankCmpId' },
        { label: '开户账号', value: 'openAccNo' },
        { label: '开户行', value: 'openBankName' },
        { label: '开户行网点', value: 'openBankNet' },
        { label: '状态', value: 'state', child: [{ id: '01', val: '审核通过' }, { id: '02', val: '待平台审核' }, { id: '10', val: '待银行审核' }, { id: '11', val: '银行拒绝' }, { id: '-1', val: '平台拒绝' }] },
        { label: '备注', value: 'remark' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      cmpBankinfoDetail({ id: this.$route.query.id }, res => {
        this.meetingInformationDetails = { ...res.data }
      })
    }
    if (this.$route.query.withParameters) {
      this.activeName = 'two'
    }
    if (this.$route.query.cmpId && this.$route.query.settleBankId) {
      this.getCashAccountDetail()
    }
  },
  methods: {
    // 获取提现账户列表
    getCashAccountDetail() {
      cashAccountDetail({ cmpId: this.$route.query.cmpId, settleBankId: this.$route.query.settleBankId }, res => {
        this.listData = [...res.data]
      })
    },
    // 点击新增绑定按钮
    addCashAccount() {
      if (this.meetingInformationDetails.state !== '01') {
        this.$message('您的账户还未审核通过，暂不支持添加提现账户！')
        return
      }
      if (this.listData.length >= 10) {
        this.$message('您的提现账户已有十条，暂不支持添加')
        return
      }
      this.$refs.dialog.editFormVisible = true
      this.addFormData = {}
    },
    getFormData(value) {
      value.id = this.$route.query.id
      cashAccountAdd(value, () => {
        this.$message.success('添加成功！')
        this.$refs.dialog.editFormVisible = false
        this.getCashAccountDetail()
      })
    }
  }
}
</script>

<style lang="scss">
.bankAccountDetails {
  .el-descriptions {
    padding: 32px;
    .el-descriptions-row td {
      padding-bottom: 32px;
    }
  }
  .el-tabs {
    .el-button {
      margin: 32px 50% 12px;
    }
  }
}
</style>
