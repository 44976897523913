// 账户管理系统
import { postRequest } from './index'
// 会员开户信息分页
export const cmpBankinfoPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 审核
export const cmpBankinfoAudit = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增
export const cmpBankinfoAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 详情
export const cmpBankinfoDetail = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现账户详情
export const cashAccountDetail = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/cashAccountDetail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现账户添加
export const cashAccountAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/cashAccountAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现新增
export const inoutLogOutAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/outAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 账户信息
// 账户信息分页
export const accountInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/accountInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查看资金明细
export const flowDetailPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/flowDetail/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 会员充值
// 充值分页
export const inoutLogPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 充值新增
export const inoutLogPayAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/payAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 充值审核
export const inoutLogPayAudit = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/payAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提现审核
export const inoutLogOutAudit = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/outAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 保付子合约签约
export const abcBailPaySubContractSign = (data, successCallback, failureCallback) => {
  postRequest(`/abc/bailPay/subContractSign`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

